var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed_margin"},[_c('validation-observer',{ref:"spec_item_form",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"fixed_margin_top",attrs:{"for":"name"}},[_vm._v("Item Name")]),_c('b-form-input',{attrs:{"placeholder":"Ex. Access Control"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(errors[0])?_c('br'):_vm._e()]}}])}),_c('label',{staticClass:"fixed_margin_top",attrs:{"for":"name"}},[_vm._v("Description for clients "),_c('small',{staticClass:"text-muted"},[_vm._v(" optional")])]),_vm._v(" "),_c('br'),_c('b-form-textarea',{attrs:{"placeholder":"Ex. HID-compatible system, 2x entry doors"},model:{value:(_vm.form.details),callback:function ($$v) {_vm.$set(_vm.form, "details", $$v)},expression:"form.details"}}),_c('small',{staticClass:"text-muted"},[_vm._v("Shown to clients if the item is not hidden in turnkey.")]),_c('br'),_c('validation-provider',{attrs:{"name":"Internal Note","rules":{required:_vm.form.is_base_spec}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"fixed_margin_top",attrs:{"for":"internal note"}},[_vm._v("Internal Note "),_c('small',{staticClass:"text-muted"},[_vm._v("optional")])]),_c('b-form-textarea',{attrs:{"placeholder":"Internal notes and reasos for changes."},model:{value:(_vm.form.internal_note),callback:function ($$v) {_vm.$set(_vm.form, "internal_note", $$v)},expression:"form.internal_note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),(errors[0])?_c('br'):_vm._e(),_c('small',{staticClass:"text-muted"},[_vm._v("This field is required when making an adjustment to base spec.")])]}}])}),_c('br'),_c('validation-provider',{attrs:{"name":"Budget Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"fixed_margin_top",attrs:{"for":"select"}},[_vm._v("Select Budget Group")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.option},on:{"input":_vm.toggleChanged},model:{value:(_vm.form.budget_group),callback:function ($$v) {_vm.$set(_vm.form, "budget_group", $$v)},expression:"form.budget_group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"fixed_margin_top"},[_c('validation-provider',{attrs:{"name":"Tender Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"select"}},[_vm._v("Tender code")]),_c('v-select',{attrs:{"label":"code_name","options":_vm.form.budget_group == 'Construction'
              ? _vm.uniformat.Construction
              : _vm.form.budget_group == 'FF&E'
                ? _vm.uniformat['FF&E']
                : _vm.form.budget_group == 'AV/IT'
                  ? _vm.uniformat.AVIT
                  : _vm.uniformat['Soft Costs'],"item-text":"code_name","required":"","reduce":function (tenderCode) { return tenderCode.code; }},model:{value:(_vm.form.uniformat),callback:function ($$v) {_vm.$set(_vm.form, "uniformat", $$v)},expression:"form.uniformat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"row fixed_margin_top"},[_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"name":"Total Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{attrs:{"for":"price"}},[_vm._v("Total Price (w/ D&I)")]),_c('b-form-group',[_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"$0.00"},model:{value:(_vm.form.total_price),callback:function ($$v) {_vm.$set(_vm.form, "total_price", $$v)},expression:"form.total_price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"name":"Total COGS","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{attrs:{"for":"price"}},[_vm._v("Total COGS")]),_c('b-form-group',[_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"$0.00"},model:{value:(_vm.form.total_cost),callback:function ($$v) {_vm.$set(_vm.form, "total_cost", $$v)},expression:"form.total_cost"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"col-4"},[_c('validation-provider',{attrs:{"name":"Other Profit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{attrs:{"for":"price"}},[_vm._v("Total Other Profit")]),_c('b-form-group',[_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"$0.00"},model:{value:(_vm.form.rebate_unit),callback:function ($$v) {_vm.$set(_vm.form, "rebate_unit", $$v)},expression:"form.rebate_unit"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"col-12"},[(_vm.form.budget_group == 'AV/IT')?_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":true,"unchecked-value":false,"disabled":""},model:{value:(_vm.form.is_base_spec),callback:function ($$v) {_vm.$set(_vm.form, "is_base_spec", $$v)},expression:"form.is_base_spec"}},[_vm._v(" AV/IT items cannot be rolled up in Base Contract ")]):(_vm.form.budget_group == 'Soft Cost')?_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":true,"unchecked-value":false,"disabled":""},model:{value:(_vm.form.is_base_spec),callback:function ($$v) {_vm.$set(_vm.form, "is_base_spec", $$v)},expression:"form.is_base_spec"}},[_vm._v(" Soft Cost items cannot be rolled up in Base Contract. ")]):_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":true,"unchecked-value":false,"disabled":_vm.form.isAdjustment},model:{value:(_vm.form.is_base_spec),callback:function ($$v) {_vm.$set(_vm.form, "is_base_spec", $$v)},expression:"form.is_base_spec"}},[_vm._v(" "+_vm._s(_vm.form.isAdjustment ? 'All Adjustments are Hidden in Turnkey' : 'Roll Up in Base Contract')+" ")])],1)])],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"my-2",attrs:{"variant":"primary"},on:{"click":_vm.addSpecificationItem}},[_vm._v("Save Addition ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }