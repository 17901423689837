<template>
  <div class="fixed_margin">
    <validation-observer
      ref="spec_item_form"
      tag="form"
    >
      <!--      <validation-provider-->
      <!--        #default="{ errors }"-->
      <!--        name="Specification Name"-->
      <!--        rules="required"-->
      <!--      >-->

      <!--        <label for="name">Specification name:</label>-->
      <!--        <b-form-input v-model="form.name" />-->
      <!--        <small class="text-danger">{{ errors[0] }}</small>-->
      <!--      </validation-provider>-->
      <validation-provider
        #default="{ errors }"
        name="Name"
        rules="required"
      >
        <label
          class="fixed_margin_top"
          for="name"
        >Item Name</label>
        <b-form-input
          v-model="form.name"
          placeholder="Ex. Access Control"
        />
        <small class="text-danger">{{ errors[0] }}</small>
        <br v-if="errors[0]">
      </validation-provider>
      <label
        class="fixed_margin_top"
        for="name"
      >Description for clients <small class="text-muted">
        optional</small></label> <br>
      <b-form-textarea
        v-model="form.details"
        placeholder="Ex. HID-compatible system, 2x entry doors"
      />
      <small class="text-muted">Shown to clients if the item is not hidden in turnkey.</small>
      <br>
      <validation-provider
        #default="{ errors }"
        name="Internal Note"
        :rules="{required:form.is_base_spec}"
      >
        <label
          class="fixed_margin_top"
          for="internal note"
        >Internal Note <small
          class="text-muted"
        >optional</small></label>
        <b-form-textarea
          v-model="form.internal_note"
          placeholder="Internal notes and reasos for changes."
        />
        <small class="text-danger">{{ errors[0] }}</small> <br v-if="errors[0]">
        <small class="text-muted">This field is required when making an adjustment to base spec.</small>
      </validation-provider>
      <br>
      <validation-provider
        #default="{ errors }"
        name="Budget Group"
        rules="required"
      >
        <label
          class="fixed_margin_top"
          for="select"
        >Select Budget Group</label>
        <v-select
          v-model="form.budget_group"
          :clearable="false"
          :options="option"
          @input="toggleChanged"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <div class="fixed_margin_top">
        <validation-provider
          #default="{ errors }"
          name="Tender Code"
          rules="required"
        >
          <label for="select">Tender code</label>
          <v-select
            v-model="form.uniformat"
            label="code_name"
            :options="
              form.budget_group == 'Construction'
                ? uniformat.Construction
                : form.budget_group == 'FF&E'
                  ? uniformat['FF&E']
                  : form.budget_group == 'AV/IT'
                    ? uniformat.AVIT
                    : uniformat['Soft Costs']
            "
            item-text="code_name"
            required
            :reduce="(tenderCode) => tenderCode.code"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <div class="row fixed_margin_top">
          <div class="col-4">
            <validation-provider
              #default="{ errors }"
              name="Total Price"
              rules="required"
            >
              <label for="price">Total Price (w/ D&I)</label>
              <b-form-group>
                 <b-input-group prepend="$">
                  <b-form-input
                    v-model="form.total_price"
                    type="number"
                    placeholder="$0.00"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-4">
            <validation-provider
              #default="{ errors }"
              name="Total Price"
              rules="required"
            >
              <label for="price">Total COGS</label>
              <b-form-group>
                <b-input-group prepend="$">
                  <b-form-input
                    v-model="form.total_cost"
                    type="number"
                    placeholder="$0.00"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-4">
            <validation-provider
                #default="{ errors }"
                name="Other Profit"
                rules="required"
            >
              <label for="price">Total Other Profit</label>
              <b-form-group>
                <b-input-group prepend="$">
                  <b-form-input
                      v-model="form.rebate_unit"
                      type="number"
                      placeholder="$0.00"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="col-12">
            <b-form-checkbox
              v-if="form.budget_group == 'AV/IT'"
              id="checkbox-1"
              v-model="form.is_base_spec"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              disabled
            >
              AV/IT items cannot be rolled up in Base Contract
            </b-form-checkbox>
            <b-form-checkbox
              v-else-if="form.budget_group == 'Soft Cost'"
              id="checkbox-1"
              v-model="form.is_base_spec"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              disabled
            >
              Soft Cost items cannot be rolled up in Base Contract.
            </b-form-checkbox>
            <b-form-checkbox
              v-else
              id="checkbox-1"
              v-model="form.is_base_spec"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              :disabled="form.isAdjustment"
            >
              {{
                form.isAdjustment
                  ? 'All Adjustments are Hidden in Turnkey'
                  : 'Roll Up in Base Contract'
              }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </validation-observer>
    <div class="text-right">
      <b-button
        variant="primary"
        class="my-2"
        @click="addSpecificationItem"
      >Save Addition
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton, BCol, BFormCheckbox, BFormInput, BFormRadio, BFormTextarea, BInputGroup, BOverlay, BRow, BTable, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { UilGripHorizontalLine, UilTimes } from '@iconscout/vue-unicons'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import tenderCodes from '@/assets/objects/tender-codes.json'
import uniformat from '@/assets/objects/uniformat-codes.json'

export default {
  components: {
    BFormCheckbox,
    BOverlay,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    BCol,
    BRow,
    UilTimes,
    UilGripHorizontalLine,
    BInputGroup,
    BTable,
    BFormRadio,
    required,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
  },
  props: {
    specItem: {
      type: Object,
    },
    base_items: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      step: 1,
      option: ['FF&E', 'AV/IT', 'Construction', 'Soft Cost'],
      specification_type: 'reuse',
      form: {
        id: null,
        name: null,
        budget_group: 'FF&E',
        is_base_spec: false,
        total_cost: 0,
        total_price: 0,
        cost_per_sqft: 0,
        price_per_sqft: 0,
        unit_cost: 0,
        unit_price: 0,
        quantity: 0,
        is_adjustment: false,
        construction_work_id: null,
        internal_note: null,
        is_site_condition: false,
        is_spec_swap: false,
        uniformat: null,
        details: null,
        tender_code: null,
        rebate_unit: 0,
      },
      emptyForm: {
        id: null,
        name: null,
        budget_group: 'FF&E',
        is_base_spec: false,
        total_cost: 0,
        total_price: 0,
        cost_per_sqft: 0,
        price_per_sqft: 0,
        unit_cost: 0,
        unit_price: 0,
        quantity: 0,
        is_adjustment: false,
        construction_work_id: null,
        internal_note: null,
        is_site_condition: false,
        is_spec_swap: false,
        uniformat: null,
        details: null,
        tender_code: null,
        rebate_unit: 0,
      },
      selected_item: this.specItem.budget_group === 'CNST' ? this.specItem.construction_work : this.specItem.element,
      tenderCodes,
      uniformat,
    }
  },
  computed: {
    tc() {
      let tc = []
      if (Number(this.base_spec_version) < 3) {
        tc = tenderCodes.v1
      } else {
        tc = tenderCodes.v2.filter(c => c.cost_code != '0901')
      }
      return tc
    },
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + (Number(this.form.markup) / 100)),
      )
    },
  },
  methods: {
    clearSelects() {
    },
    addSpecificationItem() {
      return new Promise((resolve, reject) => {
        this.$refs.spec_item_form.validate()
          .then(success => {
            if (success) {
              // console.log(this.form)
              this.$emit('saveItem', this.form)
            } else {
              reject()
            }
          })
      })
    },
    toggleChanged() {
      this.form.is_base_spec = false
      if (this.form.is_base_spec == true && this.form.budget_group != 'Construction') {
        this.form.is_base_spec = false
        this.form.is_base_spec = false
      }
      if (this.form.budget_group == 'Soft Cost' || this.form.budget_group == 'AV/IT') {
        // this.price = this.cost;
        this.isBaseSpec = false
      }
      this.form.uniformat = null
      this.form.tender_code = null
    },
  },
}
</script>
<style scoped>
.fixed_margin {
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 15px;
}

.fixed_margin_top {
  margin-top: 15px;
}
</style>
